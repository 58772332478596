<template>
  <div class="backend">
    <nav-menu :active="key"></nav-menu>
    <menu-list></menu-list>
    <section class="app-main">
      <transition
        name="fade-transform"
        mode="out-in">
        <keep-alive>
          <router-view :key="key" />
        </keep-alive>
      </transition>
    </section>
  </div>
</template>

<script>
import navMenu from '@/components/NavMenu';
import menuList from '@/components/Menulist.vue';

export default {
  name: 'backend',
  components: {
    navMenu,
    menuList,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.backend {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .app-main {
    width: 100%;
    position: relative;
    height: calc(100% - 90px);
    padding-left: 200px;
    overflow: hidden;
  }
}
</style>
