<template>
  <div class="menulist">
    <el-menu
      :default-active="$route.path"
      :collapse="isCollapse"
      class="el-menu-vertical-demo"
      text-color="#606266"
      active-text-color="#5b03e4"
      :router="true">
      <el-menu-item
        v-for="item in menuList"
        :index="item.path"
        :key="item.path">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'MenuList',
  data() {
    return {
      isCollapse: false,
      menuList: [
        {
          name: '应用中心',
          icon: 'el-icon-menu',
          path: '/console/application',
        },
        { name: '配置管理', icon: 'el-icon-s-tools', path: '/console/third' },
        {
          name: '用户数据',
          icon: 'el-icon-user-solid',
          path: '/console/userdata',
        },
        { name: '充值数据', icon: 'el-icon-s-grid', path: '/console/paydata' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.menulist {
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
  width: 200px !important;
  height: 100%;
  position: fixed;
  font-size: 0;
  top: 90px;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  .el-menu {
    height: 100%;
  }
  .el-menu-item:focus,
  .el-menu-item:hover,
  .el-menu-item.is-active {
    background-color: #f9eeff;
  }
}
</style>
